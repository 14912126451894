<template>
  <div>
   <div class="flex " style="padding-top:10px ;background:#fff">
    <div class="m-l1">
      <a-select ref="select" allowClear v-model:value="fromOption.invoiceCarrier" style="width:200px"  placeholder="请选择主体">
      <a-select-option v-for="opt in invoiceCarrierList" :key="opt.id" :value="opt.subjectName">{{ opt.subjectName }}</a-select-option>
    </a-select>
    </div>
    <div class="m-l1">
      <a-select ref="select" @change="changeType"  v-model:value="fromOption.dateType"  style="width:200px"  >
      <a-select-option v-for="opt in dateTypeList" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
    </a-select>
    </div>
    <div class="m-l1" v-if="fromOption.dateType == 2">
      <a-month-picker :allowClear="false" v-model:value="monthVal" placeholder="请选择年月">
      </a-month-picker>
    </div>
    <div class="m-l1" v-else>
      <a-date-picker :value="yearVal"  :allowClear="false"  format='YYYY'  @openChange="openChangeOne"
                @panelChange="panelChangeOne" :open='yearShowOne'   mode="year" placeholder="请选择年份">
      </a-date-picker>
    </div>
    <div class="btnBox m-b1 m-l1">
      <a-button type="primary" class="m-r1" @click="searchBtn">搜索</a-button>
      <a-button  class="m-r1" @click="resetBtn">重置</a-button>
     </div>
   </div>

   <div class="m-t1">
    <a-table bordered :dataSource="dataSource" :pagination="pagination" @change="changePage" :loading="loading" :columns="columns" :rowKey="(record, index) => { return index;}">
      <template #order="{index}">
         {{ index + 1 }}
      </template>
      <template #add="{ record }">
        <a @click="lookAdd(record)">查看</a>
      </template>
    </a-table>
   </div>
    <LookDetails :departmentList="departmentList" v-if="visible" :lookItem="lookItem" v-model:visible="visible"></LookDetails>
  </div>
</template>

<script setup>
import moment from 'moment';
import { onMounted, reactive, ref } from 'vue';
import { invoiceStatistic } from '@/api/finance/finance'
import { getSubjectList } from '@/api/trans/order'
import LookDetails  from './lookDetails.vue';
import { getOrgTreeList } from "@/api/global";
import { processingTime } from './index.js'
const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
})
const fromOption = reactive({
  invoiceCarrier:null,
  dateType:2,
})
const departmentList = ref([])
const invoiceCarrierList = ref([])
const loading = ref(false)
const yearShowOne = ref(false)
const monthFormat = 'YYYY-MM';
const monthForma = 'YYYY';
const visible = ref(false)
const monthVal = ref('')
const yearVal = ref(null)
//重置
const resetBtn = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
  let str = month < 10 ? '0' : ''
  let yearMonth = year + '-' + str  +month
  monthVal.value =  moment(yearMonth, monthFormat)
  fromOption.invoiceCarrier = null
  fromOption.dateType = 2
  yearVal.value =  moment(remakeYear.value, monthForma)
  searchBtn()
}
const lookItem = ref({})
const lookAdd = (item) => {
      invoiceCarrierList.value.forEach( opt => {
        if(item.invoiceCarrier == opt.subjectName){
          item.invoiceCarrierID = opt.id
                }
      })
      lookItem.value = item
  visible.value = true

}
const changePage = (val) => {
  pagination.value = val
  reqInvoiceStatistic()
}
const openChangeOne = () => {
  yearShowOne.value =!yearShowOne.value
}

const panelChangeOne = (val) => {
  const currentDate = new Date(val);
  const year = currentDate.getFullYear();
  yearVal.value = moment(year, monthForma)
  yearShowOne.value =!yearShowOne.value
}

const searchMsg = ref({
    invoiceCarrier: null,
    dateType:fromOption.dateType,
})
//搜索
const searchBtn = () => {
  searchMsg.value = fromOption
  let time = ''
  if(fromOption.dateType== 2){
    time = processingTime( monthVal.value)
  }else{
    const currentDate = new Date( yearVal.value);
    const year = currentDate.getFullYear();
    time = year
  }
  yearMonth.value = time
  reqInvoiceStatistic()
}

const dateTypeList = ref([
  {
        id: 2,
        name: "按月份",
      },
      {
        id: 1,
        name: "按年度",
      },
])

const columns = ref([
  {
    title: '序号',
    dataIndex: 'order',
    align: 'center',
    slots:{ customRender:'order' }
  },
  {
    title: '月份',
    dataIndex: 'dateTime',
    align: 'center',
  },
  {
    title: '主体名称',
    dataIndex: 'invoiceCarrier',
    align: 'center',
  },
  {
    title: '每月开票额度(元)',
    dataIndex: 'monthQuota',
    align: 'center',
  },
  {
    title: '已开额度(元)',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '剩余额度(元)',
    dataIndex: 'availQuota',
    align: 'center',
  },
  {
    title: '已红冲金额(元)',
    dataIndex: 'invoiceRedAmt',
    align: 'center',
  },
  {
    title: '已作废金额(元)',
    dataIndex: 'invoiceNullifyAmt',
    align: 'center',
  },
  {
    title: '详情',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' }
  },
])
const dataSource = ref([])
const reqInvoiceStatistic = () => {
  loading.value = true
  // if(!searchMsg.value.dateTime){
    searchMsg.value.dateTime = yearMonth.value
  // }
  searchMsg.value.current = pagination.value.current
  searchMsg.value.size = pagination.value.pageSize
  invoiceStatistic(searchMsg.value).then(res => {
    if(res.code !== 10000) return
    dataSource.value = res.data.records
    pagination.value.total = res.data.total
  }).finally( () => {
    loading.value = false
  })
}
const remakeYear = ref('')
const changeType = (val) => {
  if(val == 1){
    if(!yearVal.value){
      const currentDate = new Date();
     const year = currentDate.getFullYear();
     remakeYear.value = year
     yearVal.value =  moment(year, monthForma)
    }
  }
}
//获取主体列表
const reqGetSubjectList = (id,name) => {
      getSubjectList(id).then( res => {
        if(res.code !== 10000) return
        if(id == 0){
          invoiceCarrierList.value = res.data
        }else{
          res.data.forEach( item => {
          item.label = item.subjectName + `【${item.taxItem}】`
          })
          state.taxItem.opt = res.data
        }
      })
    }
     //获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    departmentList.value = treeList;
  });
};
const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
  const str = month < 10 ? '0' : ''
  const yearMonth = ref(year + '-' + str  +month)
onMounted(async() => {
  await reqGetSubjectList(0)
  monthVal.value =  moment(yearMonth.value, monthFormat)
  reqInvoiceStatistic()
  getDepartment()
})
</script>

<style lang="less" scoped>

</style>