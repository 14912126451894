<template>
<div>
<SearchList  :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
<div class="m-b1 fz-14" style="color:#344563">
  <span>合计开票金额：</span><span>￥{{invoiceInfo?.totalAmt || 0 }} </span>
  <span class="m-l1"> 税金：</span><span style="color:#0066CC">￥{{ invoiceInfo?.invoiceCost|| 0 }}</span>
</div>
<a-table size="small" :loading="loading" @change="changePage" :pagination="pagination" bordered :dataSource="dataSource" :columns="lookColumns" :rowKey="(record, index) => { return index;}">
       <template #srot="{index}">
        {{ index + 1 }}
       </template>
       <template #invoiceMethod="{ record }">
        <div>{{ record.invoiceMethod?.label }}</div>
        <div> {{ record.ticketCategory?.label }}</div>
       </template>
       <template #remark="{record}">
           <div v-if="record.remark.length<11" class="remark">{{ record.remark  }}</div>
           <div v-else>
            <a-popover trigger="hover">
            <template #content>
              <div style="width:300px">{{ record.remark  }}</div>
            </template>
            <div  class="remark">{{ record.remark  }}</div>
          </a-popover>
           </div>
       </template>
</a-table>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { invoicePageList, totalInvoiceAmt } from '@/api/finance/finance'
import moment from 'moment';
import { processingTime } from './index.js'
const props = defineProps({
  timesType:{
    type:String,
    defaule:''
  },
  timesItem:{
    type:Object,
    defaule:{}
  },
  numListVisible:{
    type:Boolean,
    defaule:false
  },
  invoiceCarrier:{
    type:String,
    defaule:''
  },
  taxRate:{
    type:Number,
    defaule:0
  },
  
})
const pagination = ref({
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (totals) => `共 ${totals} 条`
  }) 
const invoiceInfo = ref({})
const loading = ref(false)  
const dataSource = ref([])
const iptData = ref([])
const iptData1 = ref([
  {
    type: "selectTimeYM",
    placeholder: "时间",
    value: '',
    prop: "dateTime",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发票号",
    value: null,
    prop: "invoiceNum",
    width: 150,
  },
  {
    type: "input",
    placeholder: "流程编号",
    value: null,
    prop: "flowInstanceId",
    width: 170,
  },
  {
    type: "input",
    placeholder: "购方单位/抬头名称",
    value: null,
    prop: "purchasingUnit",
    width: 170,
  },
  {
    type: "input",
    placeholder: "开票人",
    value: null,
    prop: "sponsor",
    width: 150,
  },
  {
    type: "select",
    placeholder: "开票类型",
    value: null,
    prop: "invoiceMethod",
    width: 150,
    opt:[
      {
        name:'纸质发票',
        id:1
      },
      {
        name:'电子发票',
        id:2
      },
    ]
  },
  {
    type: "select",
    placeholder: "票类",
    value: null,
    prop: "ticketCategory",
    width: 150,
    opt:[
      {
        name:'增值税专用发票',
        id:1
      },
      {
        name:'普通发票',
        id:2
      },
    ]
  },
])
const iptData2 = ref([
  {
    type: "selectTimeYM",
    placeholder: "时间",
    value: '',
    prop: "dateTime",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发票号",
    value: null,
    prop: "invoiceNum",
    width: 150,
  },
  {
    type: "input",
    placeholder: "流程编号",
    value: null,
    prop: "flowInstanceId",
    width: 170,
  },
  {
    type: "input",
    placeholder: "关联流程编号",
    value: null,
    prop: "frontFlowInstanceId",
    width: 170,
  },
  {
    type: "input",
    placeholder: "购方单位/抬头名称",
    value: null,
    prop: "purchasingUnit",
    width: 170,
  },
  {
    type: "input",
    placeholder: "开票人",
    value: null,
    prop: "sponsor",
    width: 150,
  },
  {
    type: "select",
    placeholder: "开票类型",
    value: null,
    prop: "invoiceMethod",
    width: 150,
    opt:[
      {
        name:'纸质发票',
        id:1
      },
      {
        name:'电子发票',
        id:2
      },
    ]
  },
  {
    type: "select",
    placeholder: "票类",
    value: null,
    prop: "ticketCategory",
    width: 150,
    opt:[
      {
        name:'增值税专用发票',
        id:1
      },
      {
        name:'普通发票',
        id:2
      },
    ]
  },
])
const lookColumns = ref([])
const lookColumns1 = ref([
  {
    title: '序号',
    dataIndex: 'srot',
    align: 'center',
    slots:{ customRender:'srot'}
  },
  {
    title: '开票日期',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '发票号',
    dataIndex: 'invoiceNum',
    align: 'center',
  },
  {
    title: '流程编号',
    dataIndex: 'flowInstanceId',
    align: 'center',
  },
  {
    title: '开票人',
    dataIndex: 'sponsor',
    align: 'center',
  },
  {
    title: '所属部门',
    dataIndex: 'superOrgName',
    align: 'center',
  },
  {
    title: '发票金额(元)',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '税金(元)',
    dataIndex: 'invoiceCost',
    align: 'center',
  },
  {
    title: '购方单位/抬头名称',
    dataIndex: 'purchasingUnit',
    align: 'center',
  },
  {
    title: '开票类型',
    dataIndex: 'invoiceMethod	',
    align: 'center',
    slots: {
      customRender: 'invoiceMethod'
    }
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    width: '100px',
    slots:{
      customRender:'remark'
    }
  },
])
const lookColumns2 = ref([
  {
    title: '序号',
    dataIndex: 'srot',
    align: 'center',
    slots:{ customRender:'srot'}
  },
  {
    title: '开票日期',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '发票号',
    dataIndex: 'invoiceNum',
    align: 'center',
  },
  {
    title: '流程编号',
    dataIndex: 'flowInstanceId',
    align: 'center',
  },
  {
    title: '关联流程编号',
    dataIndex: 'frontFlowInstanceId',
    align: 'center',
  },
  {
    title: '开票人',
    dataIndex: 'sponsor',
    align: 'center',
  },
  {
    title: '所属部门',
    dataIndex: 'superOrgName',
    align: 'center',
  },
  {
    title: '发票金额(元)',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '税金(元)',
    dataIndex: 'invoiceCost',
    align: 'center',
  },
  {
    title: '购方单位/抬头名称',
    dataIndex: 'purchasingUnit',
    align: 'center',
  },
  {
    title: '开票类型',
    dataIndex: 'invoiceMethod	',
    align: 'center',
    slots: {
      customRender: 'invoiceMethod'
    }
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    width: '100px',
    slots:{
      customRender:'remark'
    }
  },
])
const searchMsg = ref({})
//重置
const resetBtn = () => {
  searchMsg.value = {}
  reqInvoicePageList()
}
const searchAdd = (val) => {
  let msg = JSON.parse(JSON.stringify(val)) 
  msg.dateTime = processingTime( msg.dateTime)
  searchMsg.value = msg
  reqInvoicePageList()
}
const reqInvoicePageList = (() => {
  loading.value = true;
  let msg = {
    size:pagination.value.pageSize,
    current:pagination.value.current,
    taxRate:props.taxRate,
    invoiceCarrier:props.invoiceCarrier,
    dateTime:props.timesItem.dateTime,
    superOrgId :props.timesItem.superOrgId,
    applyInvoiceType:props.timesType
  }
  Object.assign(msg,searchMsg.value)
  invoicePageList(msg).then( res => {
    if(res.code !== 10000) return
    reqtotalInvoiceAmt(msg)
    pagination.value.total = res.data.total
    dataSource.value = res.data.records
  }).finally(() => {
    loading.value = false
  })
})
const changePage = (val) => {
  pagination.value = val
  reqInvoicePageList()
 } 
const  reqtotalInvoiceAmt = (msg) => {
  totalInvoiceAmt(msg).then( res =>{
    if(res.code !== 10000) return
    invoiceInfo.value = res.data
  })
}
 const monthFormat = 'YYYY-MM'; 
onMounted(() => {
  if(props.timesType == 1){
    lookColumns.value = lookColumns1.value
    iptData.value = iptData1.value
  }else{
    lookColumns.value = lookColumns2.value
    iptData.value = iptData2.value
  }
  iptData.value[0].value =  moment(props.timesItem.dateTime , monthFormat) 
  reqInvoicePageList()
})
</script>

<style lang="less" scoped>
.remark{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>