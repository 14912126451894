<template>
  <div>
    <a-modal :visible="visible" width="50%" :title="title" :footer="null" @cancel="cancel">
      <div class="flex " style="padding-bottom: 10px ">
        <div><a-month-picker :allowClear="false" v-model:value="monthVal" placeholder="请选择年月">
          </a-month-picker></div>
        <div class="m-l1">
          <a-select ref="select" allowClear v-model:value="fromOption.superOrgId" style="width:200px" placeholder="部门">
            <a-select-option v-for="opt in departmentList" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
          </a-select>
        </div>
        <div class="btnBox m-b1 m-l1">
          <a-button type="primary" class="m-r1" @click="searchBtn">搜索</a-button>
          <a-button class="m-r1" @click="resetBtn">重置</a-button>
        </div>
      </div>
      <a-table size="small" :loading="loading" bordered :dataSource="dataSource" :pagination="false" :columns="lookColumns" :rowKey="(record, index) => { return index;}">
        <template #times="{ record}">
          <a @click="lookTimes(record,1)">{{ record.times }}</a>
        </template>
        <template #invoiceRedCount="{ record}">
          <a @click="lookTimes(record,3)">{{ record.invoiceRedCount }}</a>
        </template>
        <template #invoiceNullifyCount="{ record}">
          <a @click="lookTimes(record,2)">{{ record.invoiceNullifyCount }}</a>
        </template>
      </a-table>
    </a-modal>
    <a-modal v-model:visible="numListVisible"  width="75%" :title="titleMsg" :footer="null" >
     <DetailList v-if="numListVisible" :taxRate="props.lookItem.taxRate"  :invoiceCarrier="invoiceCarrier" :timesType="timesType" :timesItem="timesItem"></DetailList>
      </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { departStatistic } from '@/api/finance/finance'
import { processingTime } from './index.js'
import moment from 'moment';
import DetailList from './detailList'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookItem:{
    type: Object,
    default: {}
  },
  departmentList:{
    type: Array,
    default: []
  },
  invoiceCarrier:{
    type: String,
    default: ''
  },
 
})
const titleMsg = ref('')
const title = ref('')
const numListVisible = ref(false)
const loading = ref(false)
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
const fromOption = reactive({
  invoiceCarrier:null,
  taxRate:null,
  dateTime:null,
  superOrgId:null
})
const monthFormat = 'YYYY-MM'; 
const dataSource = ref([])
const monthVal = ref('')
const emit = defineEmits(['update:visible'])
const cancel = () => {
  emit('update:visible', false)
}
//查看明细
const timesType = ref('')
const timesItem = ref({})
const lookTimes = (item,type) => {
let typeStr = ''
if(type == 1){
  typeStr = '开票明细'
}else{
  typeStr = type==2?'作废明细':'红冲明细'  
}
titleMsg.value = props.invoiceCarrier + ' - '+ props.lookItem.taxRate + '%' + ' - '+ props.lookItem.taxItem + ' - ' + item.dateTime + ' - ' +typeStr
  timesItem.value = item
  timesType.value = type
  numListVisible.value = true

}
// 重置
const resetBtn = () => {
  let str = month < 10 ? '0' : ''
  let yearMonth = year + '-' + str  +month
  monthVal.value =  moment(yearMonth, monthFormat)
  fromOption.dateTime = yearMonth
  fromOption.superOrgId = null
  reqtaxRateStatistic()
}
const searchBtn = () => {
  fromOption.dateTime = processingTime(monthVal.value)
  reqtaxRateStatistic()
}
 const reqtaxRateStatistic = () => {
  loading.value = true;
  departStatistic(fromOption).then( res => {
    if(res.code !== 10000) return
    dataSource.value = res.data
  }).finally( () => {
    loading.value = false;
  } )
 }

const lookColumns = ref([
  {
    title: '月份',
    dataIndex: 'dateTime',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'superOrgName',
    align: 'center',
  },
  {
    title: '每月额度(元)',
    dataIndex: 'monthQuota',
    align: 'center',
  },
  {
    title: '已开额度(元)',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '开票次数',
    dataIndex: 'times',
    align: 'center',
    slots:{ customRender: 'times' }
  },
  {
    title: '已红冲金额(元)',
    dataIndex: 'invoiceRedAmt',
    align: 'center',
  },
  {
    title: '红冲次数',
    dataIndex: 'invoiceRedCount',
    align: 'center',
    slots:{ customRender: 'invoiceRedCount' }
  },
  {
    title: '已作废金额(元)',
    dataIndex: 'invoiceNullifyAmt',
    align: 'center',
  },
  {
    title: '作废次数',
    dataIndex: 'invoiceNullifyCount',
    align: 'center',
    slots:{ customRender: 'invoiceNullifyCount' }
  },
])
onMounted(() => {
  title.value =  props.invoiceCarrier + ' - '+ props.lookItem.taxRate + '%' + ' - '+ props.lookItem.dateTime
  let yearMonth =  props.lookItem.dateTime
  monthVal.value =  moment(yearMonth, monthFormat)
  fromOption.dateTime = yearMonth
  fromOption.invoiceCarrier =  props.invoiceCarrier
  fromOption.taxRate =  props.lookItem.taxRate
  reqtaxRateStatistic()
})
</script>

<style lang="less" scoped>
</style>