<template>
  <div>
    <a-modal :visible="visible" width="50%" :title="title" :footer="null" @cancel="cancel">
      <div class="flex " style="padding-bottom: 10px ">
        <div><a-month-picker :allowClear="false" v-model:value="monthVal" placeholder="请选择年月">
          </a-month-picker></div>
        <div class="m-l1">
          <a-select ref="select" allowClear v-model:value="taxItemVal" style="width:200px" placeholder="税率">
            <a-select-option v-for="opt in taxItemList" :key="opt.id" :value="opt.subjectName">{{ opt.subjectName }}</a-select-option>
          </a-select>
        </div>
        <div class="btnBox m-b1 m-l1">
          <a-button type="primary" class="m-r1" @click="searchBtn">搜索</a-button>
          <a-button class="m-r1" @click="resetBtn">重置</a-button>
        </div>
      </div>
      <a-table size="small" bordered :dataSource="dataSource" :pagination="false" :loading="loading" :columns="lookColumns" :rowKey="(record, index) => { return index;}">
        <template #taxRate="{record}">
          <span>{{ record.taxRate }}%</span>
        </template>
        <template #add="{ record }">
          <a @click="lookAdd(record)">查看</a>
        </template>
      </a-table>
    </a-modal>
    <DepDetails :departmentList="departmentList"  :invoiceCarrier="lookItem.invoiceCarrier" v-if="depVisible" :lookItem="lookDep" v-model:visible="depVisible"></DepDetails>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { getSubjectList } from '@/api/trans/order'
import { taxRateStatistic } from '@/api/finance/finance'
import  DepDetails from './depDetails'
import moment from 'moment';
import { processingTime } from './index.js'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookItem:{
    type: Object,
    default: {}
  },
  departmentList:{
    type: Array,
    default: []
  }

})
const loading = ref(false)
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
const fromOption = reactive({
  invoiceCarrier: null,
  dateTime:null,
  taxRate:null
})
const depVisible = ref(false)
const lookDep = ref({})
const title = ref('')
const monthFormat = 'YYYY-MM'; 
const taxItemList = ref([])
const dataSource = ref([])
const monthVal = ref('')
const taxItemVal= ref(null)
const emit = defineEmits(['update:visible'])
const cancel = () => {
  emit('update:visible', false)
}
// 重置
const resetBtn = () => {
  taxItemVal.value = null
  let str = month < 10 ? '0' : ''
  let yearMonth = year + '-' + str  +month
  monthVal.value =  moment(yearMonth, monthFormat)
  fromOption.dateTime = yearMonth
  fromOption.taxRate = null
  reqtaxRateStatistic()
}
//搜索
const searchBtn = () => {
  fromOption.taxRate = parseInt(taxItemVal.value)
  fromOption.dateTime = processingTime(monthVal.value)
  reqtaxRateStatistic()
}
const lookAdd = (item) => {
  lookDep.value = item
  depVisible.value = true
}
//获取主体列表
const reqGetSubjectList = (id,name) => {
      getSubjectList(id).then( res => {
        if(res.code !== 10000) return
        if(id == 0){
          invoiceCarrierList.value = res.data
        }else{
          res.data.forEach( item => {
          item.label = item.subjectName + `【${item.taxItem}】`
          })
          taxItemList.value = res.data
        }
      })
 }
 const reqtaxRateStatistic = () => {
  loading.value = true
  taxRateStatistic(fromOption).then( res => {
    if(res.code !== 10000) return
    dataSource.value = res.data
  }).finally( () => {
    loading.value = false
  })
 }
const lookColumns = ref([
  {
    title: '月份',
    dataIndex: 'dateTime',
    align: 'center',
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    align: 'center',
    slots:{ customRender:'taxRate'}
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    align: 'center',
  },
  {
    title: '每月额度(元)',
    dataIndex: 'monthQuota',
    align: 'center',
  },
  {
    title: '已开额度(元)',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '已红冲金额(元)',
    dataIndex: 'invoiceRedAmt',
    align: 'center',
  },
  {
    title: '已作废金额(元)',
    dataIndex: 'invoiceNullifyAmt',
    align: 'center',
  },
  {
    title: '部门详情',
    dataIndex: 'add',
    align: 'center',
    slots:{ customRender: 'add' }
  },
])
onMounted(() => {
  reqGetSubjectList(props.lookItem.invoiceCarrierID)
  title.value = props.lookItem.invoiceCarrier + ' - '  + props.lookItem.dateTime
  let yearMonth =  props.lookItem.dateTime
  monthVal.value =  moment(yearMonth, monthFormat)
  fromOption.invoiceCarrier = props.lookItem.invoiceCarrier
  fromOption.dateTime = yearMonth
  reqtaxRateStatistic()
})
</script>

<style lang="less" scoped>
</style>